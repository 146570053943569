*{

    font-family: 'Franklin Gothic', 'Arial Narrow', Arial, sans-serif;
}
.section{
    height: 70vh;
    width: 100%;
    /* background-image: linear-gradient(to right top, #83d1ff, #4be2ff, #2bf1eb, #64fbc4, #a8ff96); */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 4rem;
    margin-bottom: -8rem;
}
.section .title{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: -8rem;
}
.section .title h2{
    position: relative;
    text-align: start;
    font-size: 3.5rem;
    color: #000000;
    padding: 0 12px;
}
.section .title h2:before{
    content: " ";
    position: absolute;
    height: 0;
    width: 5px;
    top: 0;
    left: 0;
    background: #6EB16E;
    animation: down 3s forwards;
}

@keyframes down{
    0%{
        height: 0;
    }
    100%{
        height: 100%;
    }
}



.section .title .arrows button{
    background: transparent;
    margin: 0px 15px;
    border: 0;
    border-radius: 12px;
}
.section .title .arrows button:hover i{
    color: #000;
}
.section .title .arrows button:hover{
    background: #fff;
}
.section .title .arrows button i{
    padding: 10px 30px;
    color: white;
    font-size: 1.3rem;
    border: 1px solid #fff;
    border-radius: 12px;
}


/* section team members  */
.section .team-members{
    height: 650px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
}
.section .team-members .member-card1{
    position: relative;
    max-height: 350px;
    max-width: 200px;
    border-radius: 26px;
    margin: 0 12px;
    overflow: hidden;
    transition: 0.7s;
    display: flex;
    justify-content: center;
    align-items: center;

}
/* card icons  */
 .member-card1 .icons{
    position: absolute;
    display: none;
    flex-direction: column;
    justify-content: center;
    z-index: 1;    
    top: 20%;
    left: 10%;
    transition: 1s;
    
}
.member-card1 .icons i{
    padding: 6px 0;
    margin: 4px 0;
    color: #fff;
    font-size: 1.1rem;   
    transform: rotate(-60deg);
    transition: all 1s; 

}
/* card icons end */
 .member-card1:hover .icons{
    display: flex;
}
 .member-card1:hover .icons i{
    transform: rotate(0deg);
    transition:all 1s;
}
.icons i:hover{
    color: #eb8714;
}

.member-card1 img{
    height: 100%;
    width: 100%;
    border-radius: 26px;
    transition: all 1s ease;
}
/* content start  */
.member-card1 .content{
    position: absolute;
    z-index: 1;
    top: 72%;
}
.member-card1 .content h2{
    color: #fff;
    margin: 5px 0;
    font-size: 1rem;
    text-align: start;
    margin-left: .7rem;
}
.section .team-members .member-card1 .content h3{
    color: #dfdfdf;
    font-weight: 500;
    font-size: 1rem;
    text-align: start;
    margin-left: .7rem;

}
.section .team-members .member-card1:hover img{
    transform: scale(1.1);
}

/* cdn  */

.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}
.section {
    padding: 20px;
}

.title {
    text-align: center;
    margin-bottom: 20px;
}

.slick-next:before {
    color: black;
    margin-left: -7rem;

}
.slick-prev:before {
    color: black;

}

.slick-prev i, .slick-next i {
    font-size: 80px;
}

.team-members .member-card1 {
    text-align: center;
}
.member-card1{
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;
    width: 100%;
    width: 500px;
}
.member-card1 img {
    width: 100%;
    height: 200px;
}

.icons {
    margin: 10px 0;
}

.icons svg {
    margin: 0 5px;
    font-size: 20px;
}
.content{
    width: 100%;
}

.content h2 {
    margin: 10px 0;
}

.content h3 {
    color: #666;
}

@media(max-width:1024px){
    .section .title{
        flex-direction: column;
    }
    .section .title h2{
        transition: 1s;
        padding: 0px 18px;
        margin: 45px 0;
        font-size: 3.4rem;
    }
}
@media(max-width:900px){
    .section .title h2{
        transition: 1s;
        font-size: 3.6rem;
    }
}
@media(max-width:768px){
    .section .title h2{
        transition: 1s;
        font-size: 3.8rem;
    }
}

@media (max-width: 540px) {

    .section .team-members .member-card1{
  transform: translateX(50%);
    
    }
 }
@media(max-width:440px){
    .section .team-members .member-card1{
        transform: translateX(30%);
          
          }
    .section .title h2{
        transition: 1s;
        font-size: 3.5rem;
    }
    
}
@media(max-width: 380px){
    .section .team-members .member-card1{
        transform: translateX(0%);
          
          }
 
}
