/* Carousel */
#carouselExampleCaptions{

padding-top: 7rem;
}
.carousel-item {

  height: 80vh;
}
.carousel-item img{

  height: 80vh;
}


.carousel-caption {
  position: absolute;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.432);
}

.carousel-caption h5 {
  font-size: 2.5rem;
  color: white;
}

.carousel-caption p {
  font-size: 2rem;
  color: white;
}

#about {
  display: flex;

  margin-top: 8%;
  margin-bottom: 4%;
}

#about .card {
  padding: 2%;
  color: white;
  display: flex;
  flex-direction: row;
  backdrop-filter: blur(25px) saturate(200%);
  -webkit-backdrop-filter: blur(25px) saturate(200%);
  background-color: rgba(0, 119, 189, 0.17);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
}

#about .card img {
  height: auto;
  width: 100%;
  max-width: 45rem;
  /* Added max-width to prevent overflow */
  object-fit: cover;
  /* Ensure the image covers the area without stretching */
}

#about .card a {
  background-color: black;
  padding: 0.3rem 0.5rem;
  color: white;
  text-decoration: none;
  transition: .3s ease;
  width: 6rem;
  border: 1px solid black;
}

#about .card a:hover {
  background-color: white;
  border: 1px solid black;
  color: black;
}

#about .cardcontent {
  margin-top: 2rem;
  margin-left: 2rem;
  color: black;
}

#about .cardhead {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 2.5vw;
  text-decoration: underline;
}

.card2 {
  margin-top: 3rem;
}

.card2 button {
  margin-top: 1rem;
  background-color: transparent;
  transition: .5s ease;
}

.card2 button:hover {
  margin-top: 1rem;
  background-color: black;
}

.card2 button:hover a {
  margin-top: 1rem;
  color: white;
}

.card2 button a {
  font-size: 1.2rem;
  text-decoration: none;
  color: black;
}

.objectivecards {
  display: flex;
}

.card2head {
  margin-bottom: 2rem;
}

.objectivecard {
  margin-right: 1rem;
  padding: 1rem;
  box-shadow: 0 8px 12px -6px black;
}

.objectivecard:nth-child(1) {
  color: white;
  background-color: #2db2ff;
}

.objectivecard:nth-child(2) {
  color: rgb(0, 0, 0);
  background-color: #b7ff73;
  margin-left: 1rem;
}

.objectivecard img {
  height: 7rem;
  margin-bottom: 2rem;
}

.courses {
  margin-bottom: 4%;
  margin-left: 2%;
  border-left: 1px solid black;
  padding: 1rem;
}

.course {
  display: flex;
  width: 350px;
  background-image: linear-gradient(to right, #5bcaff, #00defc, #00eedd, #46f8a8, #acfb6a);
  margin-bottom: 2rem;
}

.coursecontent {
  padding: .5rem;
  padding-left: 1rem;
  padding-bottom: 0rem;
}

.course h5 {
  display: flex;
}

.course img {
  height: 8.5rem;
}

.course button {
  border: 1px solid black;
  outline: none;
  background-color: transparent;
  margin-top: -.5rem;
}

.course button a {
  color: black;
  text-decoration: none;
}

#application {
  background-color: #0077BD;
  padding: 2rem;
  color: white;
  display: flex;
  margin-top: 3rem;
}

.applicationcontent {
  margin-left: 5rem;
}

.appbuttons {
  display: flex;
  flex-direction: column;
  margin-left: 30rem;
}

.appbuttons img {
  height: 4rem;
  margin-bottom: 1rem;
  background-color: white;
  border-radius: 10px;
  padding: 1rem;
}

#appstore {
  padding-right: 2.5rem;
}

.applicationcontent p {
  font-size: 1.8rem;
}

.applicationcontent h2 {
  font-size: 3rem;
}

#benefits {
  display: flex;
  background-color: #B7FF73;
  margin-bottom: 2rem;
  padding: 2rem;
  box-shadow: 0 8px 12px -6px black;
}

.benefitscontent {
  margin-top: .5rem;
  margin-left: 6rem;
}

.benefitscontent p {
  font-size: 1.2rem;
}

.benefitscontent i {
  background-color: #0077BD;
  padding: .5rem;
  border-radius: 50%;
  color: white;
  margin-right: 1rem;
}

.benefitscontent button {
  background-color: transparent;
  border: 1px solid black;
  transition: .3s ease;
  margin-top: 1rem;
  float: right;
}

.benefitscontent button a {
  text-decoration: none;
  color: black;
}

.benefitscontent button:hover {
  background-color: white;
}

.benefitshead h3 {
  font-size: 2rem;
  font-weight: 400;
}

.benefitshead span {
  font-size: 1.5rem;
}

.benefitshead p {
  margin-top: 1rem;
  font-size: 1.2rem;
}

.text-center {
  text-align: center;
}
#message {
  background-color: #2fabf3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  box-shadow: 0 8px 12px -6px black;
}

.messagecontent {
  color: white;
}

.messagecontent h3 {
  font-size: 1.3rem;
}

.messagecontent h6 {
  font-size: 1.2rem;
}

.messagecontent button {
  background-color: transparent;
  border: 1px solid white;
  margin-top: 1rem;
  transition: .3s ease;
}

.messagecontent button:hover {
  background-color: black;
  border: 1px solid white;
  margin-top: 1rem;
}

.messagecontent button a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

.messageimg img {
  height: 20rem;
  border-radius: 20px;
}

#mission {
  margin-top: 5rem;
  display: flex;
  background-color: #B7FF73;
  padding: 2rem;
  box-shadow: 0 8px 12px -6px black;
}

.modal {
  --bs-modal-width: 1000px;
}

.missioncontent {
  width: 60%;
  margin-right: 10rem;

}

.modal-body {
  width: 100rem;
}

.missionyt {
  margin-top: 4rem;
}

.missioncontent p {
  margin-top: 1rem;
  font-size: 1.2rem;
  text-align: justify;
}

.missioncard {
  display: flex;
  align-items: center;
}

.missioncards {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 2rem;
}

.missioncard img {
  height: 5rem;
  margin-right: 1rem;
}


.stories {
  margin-left: 2rem;
  margin-top: 4rem;
}

.storycontent {
  margin-top: 2rem;
  margin-left: 2rem;
  display: flex;
}

.storytext p {
  font-size: 1.2rem;
  width: 80%;
  text-align: justify;
  margin-left: 1rem;

}

.story {
  display: flex;
  padding: 1rem;
  margin-right: 1rem;
  box-shadow: 0 8px 12px -6px black;

}

.storyimg img {
  height: 15rem;
  border-radius: 10px;
}

.storyimg p {
  font-size: 1.7rem;
  margin-bottom: 0rem;
}

.storyimg button {
  font-size: 1rem;
  background-color: transparent;
  outline: none;
}

.storyimg button a {
  font-size: .8rem;
  color: black;
  text-decoration: none;
}

/* Dropdown Menu */
.dropdown-menu {
  background-color: transparent;
  backdrop-filter: blur(100px);
}

.dropdown-menu .dropdown-item {
  font-size: 1rem;
  font-weight: 600;
}

/* YouTube and Facebook Section */
 #ytfb {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin-top: 5rem;
  }
  
  .youtube iframe {
    width: 80%;
    height: 20rem;
  }
  
  #membership {
    margin-bottom: 5rem;
    margin-top: 2rem;
  }
  
  .membershiphead {
    margin-bottom: 3rem;
  }
  
  .membershiphead h3 {
    text-align: center;
    margin-top: 2rem;
  }
  
  #accordionFlushExample {
    margin-top: 5rem;
  }
  
  #membership a {
    background-color: black;
    padding: 0.3rem;
    text-decoration: none;
    color: white;
    border: 1px solid white;
    transition: .3s ease;
    margin-left: 1%;
  }
  
  #membership a:hover {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border: 1px solid black;
  }
  
  .benefithead {
    text-align: center;
    margin-bottom: 4rem;
  }
  
  #benefits {
    margin-top: 5rem;
    padding-top: 2rem;
  }
  
  #benefitscontent {
    margin-left: 5%;
  }
  
  .benefitcontent {
    padding: 1rem;
    text-align: start;
  }
  
  .benefit:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .benefit:nth-child(odd) {
    background-color: #e0e0e0;
  }
  
  #benefits h2 {
    margin-bottom: 2rem;
    text-align: center;
    text-decoration: underline;
  }
  
  #meeting {
    margin-top: 6rem;
  }
  
  .meetinghead {
    margin-bottom: 3rem;
  }
  
  .meetingcontent a {
    display: block;
    font-size: 1.5rem;
    padding-bottom: 1rem;
    text-decoration: none;
    color: rgb(0, 0, 0);
  }
  
  .meetingcontent a:hover {
    color: rgb(223, 108, 108);
  }
  
  #partner {
    margin-top: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .partnerimages {
    width: 100%;
    aspect-ratio: 4/3;
    object-fit: contain;
  }
  
  #partner h2 {
    text-align: center;
    font-size: 2.5rem;
    text-decoration: underline;
  }
  
  #partner h3 {
    font-size: 2rem;
  }
  
  #partners {
    margin-top: 5rem;
    padding-bottom: 5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  
  .customer-logos {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .partnerslide {
    margin-top: 2rem;
    width: 25%;
  }
  
  #partner::before {
    position: absolute;
    content: "";
    height: 30rem;
    width: 100%;
    background-image: url('./meeting.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    opacity: .8;
  }
  
  #event::before {
    position: absolute;
    content: "";
    left: 0;
    height: 25rem;
    width: 100%;
    background-image: url('./event.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    opacity: .6;
  }
  
  #event .eventhead {
    text-align: center;
    margin-top: 5rem;
    margin-bottom: 3rem;
    text-decoration: underline;
    color: rgb(0, 0, 0);
  }
  
  .events {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  #calander {
    margin-top: 5rem;
  }
  
  .calanderhead h1 {
    text-align: center;
    margin-bottom: 5rem;
  }

  @media (max-width: 1400px) {
    #mission{
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .missioncontent{
      margin-right: 0rem;
    }
    .missioncontent h1{
      text-align: center;
    }
    .missioncontent p{
      text-align: center;
    }
    
      }
      
  @media (max-width: 1200px) {
    .carousel-item {

      height: 55vh;
    }
    .carousel-item img{
    
      height: 55vh;
    }
    #about .card{
      flex-direction: column;
    }
    #about .card img{
  height: 20rem;
  width:  20rem;
    }
    #partners {
      grid-template-columns: 1fr 1fr;
    }
    .member-card1 h2, h3{
      font-size: 2rem;
    }
    
  }

  

  

  
  @media (max-width: 992px) {
    .carousel-item {

      height: 55vh;
    }
    .carousel-item img{
    
      height: 55vh;
    }
    #benefits {
      flex-direction: column;
    }
    .benefitscontent{
      margin-left: 0rem;
    }
  .missioncard{
flex-direction: column;
    }
  .missioncard h4{
text-align: center;
margin-top: 1rem;
    }
    .carousel-caption h5 {
      font-size: 2rem;
    }
  
    .carousel-caption p {
      font-size: 1.5rem;
    }
  #about{
    flex-direction: column;
  }
    #about .card {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    #about .card img {
      max-width: 100%;
    }
  
    #about .cardcontent {
      margin-left: 0;
      margin-top: 1rem;
    }
    .courses{
      margin-top: 2rem;
      margin-left: 0rem;
      padding-left: 0rem;
      border: none;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 1rem;
      flex-wrap: wrap;
    }
  
    #ytfb {
      grid-template-columns: 1fr;
    }
  
    .youtube iframe {
      width: 100%;
      height: 15rem;
    }
  
    #partners {
      grid-template-columns: 1fr;
    }
  
    .partnerslide {
      width: 50%;
    }
  }

  @media (max-width: 790px) {

    .carousel-item {

      height: 55vh;
    }
    .carousel-item img{
    
      height: 55vh;
    }
    .messageimg{
      display: flex;
      justify-content: center;
      align-content: center;
    }
    
.courses{
  grid-template-columns: 1fr;
}
  #message{
    flex-direction: column;
  }
  .messagecontent h3, h6{
 text-align: center;
  }
  .messagecontent p{
 text-align: center;
  }
    .carousel-caption h5 {
      font-size: 1.5rem;
    }
  
    .carousel-caption p {
      font-size: 1rem;
    }
  
    #about .cardhead {
      font-size: 2rem;
    }
  
    #benefitscontent {
      margin-left: 0;
    }
  
    .benefitcontent {
      padding: 0.5rem;
    }
  
    .partnerslide {
      width: 70%;
    }
    .missionyt iframe{
      width: 400px;
    }
  }
  
  @media (max-width: 576px) {
    .carousel-item {

      height: 50vh;
    }
    .carousel-item img{
    
      height: 50vh;
    }
    
    .objectivecards{
      flex-direction: column;
    }
    .objectivecard:nth-child(2) {
      margin-left: 0rem;
      margin-top: 1rem;
    }
    .messagecontent{
      margin-left: 0rem;
    }
    .messagecontent p{
      text-align: justify;
    }
    .carousel-caption h5 {
      font-size: 1.2rem;
    }
    
    .carousel-caption p {
      font-size: 0.8rem;
    }
    
    #about .cardhead {
      font-size: 1.5rem;
    }
    
    .youtube iframe {
      height: 10rem;
    }
    
    .partnerslide {
      width: 90%;
    }
  }
  
  
  
  
  @media (max-width: 425px) {
    .carousel-item {

      height: 40vh;
    }
    .carousel-item img{
    
      height: 40vh;
    }
    .carousel-caption {
  left: 0%;
  right: 0%;
    }
    .carousel-caption h1{
      font-size: 1.3rem;
    }
    .carousel-caption p{
      font-size: 1rem;
    }
    .missioncontent{
      width: 100%;
    }
    .missioncards {
      display: block;
      grid-template-columns: 1fr;
      row-gap: 2rem;
    }
    .missionyt iframe {
      width: 350px;
    }
  
    .missioncontent{
      width: 100%;
    }
    .missioncards {
      display: block;
      grid-template-columns: 1fr;
      row-gap: 2rem;
    }
    .missionyt iframe {
      width: 350px;
    }
.course{
  width: 15rem;
}
.coursecontent{
  margin-left: -.5rem;
}
.coursecontent h5{
font-size: .9rem;
margin-bottom: 0rem;
}
.coursecontent p{
font-size: .8rem;
margin-bottom: 0rem;
}
.coursecontent button{
font-size: .8rem;
 margin-top: 0rem;
}
   
  }


  @media (max-width: 390px) {
    .carousel-item {

      height: 30vh;
    }
    .carousel-item img{
    
      height: 30vh;
    }
    .carousel-caption{
   padding-top: .5rem;
   padding-top: .5rem;
    }
    .carousel-caption h1{
      font-size: 1rem;
    }
    .carousel-caption p{
      font-size: .8rem;
    }
  }
  @media (max-width: 340px) {
    .course h5{
font-size: 1rem;
    }
   
   .missionyt iframe{
    width: 280px;
    height: 200px;
   }
  
  }